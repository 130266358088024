const baseUrl = process.env.REACT_APP_URL;

export default {
  home: {
    name: "Home",
    short: "/",
    full: baseUrl + "/",
  },
  about: {
    name: "About",
    short: "/about",
    full: baseUrl + "/about",
  },
  ourMotivation: {
    name: "Our Motivation",
    short: "/our-motivation",
    full: baseUrl + "/our-motivation",
  },
  contact: {
    name: "Contact",
    short: "/contact",
    full: baseUrl + "/contact",
  },
  explore: {
    name: "Get Answers",
    short: "/get-answers",
    full: baseUrl + "/get-answers",
  },
  myCareers: {
    name: "Give Answers",
    short: "/give-answers",
    full: baseUrl + "/give-answers",
  },
  curatedUsers: {
    name: "Curated Users",
    short: "/curated-users",
    full: baseUrl + "/curated-users",
  },
  userAnswers: {
    name: "userAnswers",
    short: "/user-answers",
    full: baseUrl + "/user-answers"
  }
};
