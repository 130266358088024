import "./Header.css";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import Cherry from "components/illustrations/Cherry/Cherry";

import { useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { SignedIn, SignedOut, UserButton, useUser } from "@clerk/clerk-react";

import routes from "services/routes";

function Header() {
  const location = useLocation();
  const [showSections, setShowSections] = useState(false);
  const { isSignedIn, user, isLoaded } = useUser();

  return (
    <div className="header">
      <div className="background"></div>

      {/* TODO: unhide when not landing page */}
      <NavLink className="home-link" to={routes.home.short}>
        <div className="logo">
          <Cherry />
        </div>
        <div className="logo-text">Picky</div>
      </NavLink>

      {isLoaded && (
        <>
          <div className={"sections" + (showSections ? " show" : "")}>
            <div
              className={
                "section" +
                (location.pathname == routes.about.short ? " selected" : "")
              }
            >
              <NavLink
                to={routes.about.short}
                onClick={() => {
                  setShowSections(false);
                }}
              >
                About
              </NavLink>
            </div>

            <div
              className={
                "section" +
                (location.pathname == routes.ourMotivation.short
                  ? " selected"
                  : "")
              }
            >
              <NavLink
                to={routes.ourMotivation.short}
                onClick={() => {
                  setShowSections(false);
                }}
              >
                Our Motivation
              </NavLink>
            </div>

            <div
              className={
                "section" +
                (location.pathname == routes.explore.short ? " selected" : "")
              }
            >
              <NavLink
                to={routes.explore.short}
                onClick={() => {
                  setShowSections(false);
                }}
              >
                {routes.explore.name}
              </NavLink>
            </div>

            <div
              className={
                "section" +
                (location.pathname == routes.myCareers.short ? " selected" : "")
              }
            >
              <NavLink
                to={routes.myCareers.short}
                onClick={() => {
                  setShowSections(false);
                }}
              >
                {routes.myCareers.name}
              </NavLink>
            </div>

            <div
              className="sections-close"
              onClick={() => {
                setShowSections(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </div>

            <div
              className={
                "section" +
                (location.pathname == routes.contact.short ? " selected" : "")
              }
            >
              <NavLink
                to={routes.contact.short}
                onClick={() => {
                  setShowSections(false);
                }}
              >
                Contact
              </NavLink>
            </div>
          </div>

          <div className="filler" />

          <div
            className="sections-show"
            onClick={() => {
              setShowSections(true);
            }}
          >
            <MenuIcon fontSize="inherit" />
          </div>

          <SignedIn>
            <UserButton afterSignOutUrl={routes.myCareers.full} />
          </SignedIn>
        </>
      )}
    </div>
  );
}

export default Header;
