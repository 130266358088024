import "./HomeView.css";

import { NavLink } from "react-router-dom";

import routes from "services/routes";

function HomeView() {
  return (
    <div className="home-view">
      <div className="landing-slogan anim-slide-appear">
        <div className="landing-slogan-inner">
          Cherry pick your career
        </div>
        <div className="landing-slogan-inner-2">
          Check out what others think about their career. Then
          decide on yours.
          <br /> Don't settle.
        </div>
        <NavLink to={routes.explore.short}>
          <div className="call-to-action">
            <div>Get Answers</div>
          </div>
        </NavLink>
      </div>
    </div>
  );
}

export default HomeView;
