import "./CuratedUsers.css";

import UserSquare from "components/misc/UserSquare/UserSquare";

import { useEffect } from "react";

const userIds = [
  "user_2dP6hJJ1DVFSskXEKR8NVO4gi9Q", // Monisha D'souza
  "user_2Z8ELbFAhX4ug3PQcPNg63WWjl3", // Sandeep Engineer
  "user_2dP6YaLGf9G4w6Bf9cjqQtk4u5I", // Bimal Patel
  "user_2dP6pvvDpOZcWz8n3R1vmDKfw90", // Nirupama Rao
  "user_2Z8EB8mzZYO8bjzeDVklQGFTKAa", // Mallika Sarabhai
  "user_2Z8Dyr8f2BlvjKIEkQQEgyiJe1g", // Geet Sethi
  "user_2Z8EXnueh0Hdlp1u0X1yZE6PfUD", // Shyamai Shodhan
];

function CuratedUsersView() {
  useEffect(() => {}, []);

  return (
    <div className="curated-users-view">
      {userIds.map((userId) => (
        <UserSquare userId={userId} />
      ))}
    </div>
  );
}

export default CuratedUsersView;
