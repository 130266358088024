import "./UserSquare.css";

import { useNavigate } from "react-router-dom";

import Loader from "../../misc/Loader/Loader";

import { useEffect, useState } from "react";
import { ClerkUser } from "services/datatypes";

import api from "services/api";

function UserSquare(props: { userId: string }) {
  const [user, setUser] = useState<ClerkUser | null>(null);
  const [userError, setUserError] = useState<Boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    api
      .getClerkUser(props.userId)
      .then((res) => {
        setUser(res);
      })
      .catch(() => {
        setUserError(true);
      });
  }, []);

  function goToUser() {
    if (userError == true || user == null) {
      return;
    }
    navigate("/user-answers/" + props.userId);
  }

  return (
    <div className="user-square">
      <div className="picture" onClick={goToUser}>
        {!userError && user == null && <Loader />}
        {!userError && user != null && (
          <>
            <div
              className="background anim-appear"
              style={{ backgroundImage: `url(${user.profile_image_url})` }}
            />
            <div className="click-here">Click here</div>
          </>
        )}
        {userError && <div className="anim-appear">Error</div>}
      </div>
      {!userError && user != null && (
        <div className="name anim-appear">
          {user.first_name} {user.last_name}
        </div>
      )}
    </div>
  );
}

export default UserSquare;
