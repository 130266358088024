import axios from "axios";

import {
  CareerSearchOptions,
  CareerExperienceForm,
  CareerExperienceCommentForm,
} from "./datatypes";

const apiUrl = process.env.REACT_APP_API_URL;
var getCache: { [key: string]: Promise<any> } = {};
var addToast = (d: any, m: string = "") => {};

// retrieve cookie value
function getCookie(name: string) {
  let cookies_string = decodeURIComponent(document.cookie);
  let cookies_string_list = cookies_string.split(";");

  var cookies: { [key: string]: string } = {};
  cookies_string_list.map((cookie_string) => {
    const cookie = cookie_string.split("=");
    cookies[cookie[0].trim()] = cookie[1].trim();
  });

  return cookies[name];
}

// hash object
function hashObject(obj: { [key: string]: string }): string {
  var hash = "";
  Object.keys(obj).map((key) => {
    hash += key + obj[key];
  });
  return hash;
}

// get authorisation header value
function getHeaders(): { [key: string]: string } {
  const cookie_token = getCookie("__session");
  // const local_token = localStorage.getItem("clerk-db-jwt");
  return {
    Authorization: cookie_token,
  };
}

// wrapper for promise, to intercept resolves or rejects and generate notification
function requestWrapper(promise: Promise<any>): Promise<any> {
  return new Promise((resolve, reject) => {
    promise
      .then((res) => {
        const data = res.data;

        // if (data.message != '') {
        //     if (data.type == 'Warning') {
        //         addToast(data.message, 'warning');
        //     } else {
        //         addToast(data.message);
        //     }
        // }

        resolve(data);
      })
      .catch((error) => {
        // const data = error.response.data;

        // if (data.message != '') {
        //     addToast(data.message, 'danger');
        // } else {
        //     addToast('An unknown error occured', 'danger');
        // }

        reject(error);
      });
  });
}

// wrapper for get request with caching
function cachedGetRequest(
  endpoint: string,
  params: { [key: string]: any } = {}
): Promise<any> {
  const get_hash = endpoint + hashObject(params);

  // check if request is cached
  if (getCache[get_hash] == null) {
    // cache promise and return it
    var request = requestWrapper(
      new Promise((resolve, reject) => {
        axios
          .get(apiUrl + endpoint, { headers: getHeaders(), params: params })
          .then((res) => {
            delete getCache[get_hash]; // once promise is resolved, remove cache
            resolve(res);
          })
          .catch((error) => {
            delete getCache[get_hash];
            reject(error);
          });
      })
    );

    getCache[get_hash] = request;
  }

  return getCache[get_hash];
}

// wrapper for post request
function postRequest(endpoint: string, data: any, isForm: boolean = false) {
  var headers = getHeaders();
  if (isForm) {
    headers["Content-Type"] = "multipart/form-data";
  }
  return requestWrapper(
    axios.post(apiUrl + endpoint, data, { headers: headers })
  );
}

// wrapper for put request
function putRequest(endpoint: string, data: any, isForm: boolean = false) {
  var headers = getHeaders();
  if (isForm) {
    headers["Content-Type"] = "multipart/form-data";
  }
  return requestWrapper(
    axios.put(apiUrl + endpoint, data, { headers: headers })
  );
}

// wrapper for delete request
function deleteRequest(endpoint: string, data: any) {
  return requestWrapper(
    axios({
      method: "delete",
      url: apiUrl + endpoint,
      data: data,
      headers: getHeaders(),
    })
  );
}

export default {
  setToastCallbacks(addToast_: (d: any, m?: string) => void) {
    addToast = addToast_;
  },

  // Clerk user
  getClerkUser: (userId: string) => {
    return cachedGetRequest("/clerk-user/", { userId: userId });
  },
  getCurrentClerkUser: () => {
    return cachedGetRequest("/current-clerk-user/");
  },

  // Careers
  getCareers: (
    parentCareerName: string | null = null,
    returnAll: boolean = false
  ) => {
    return cachedGetRequest("/careers/", {
      parentCareerName: parentCareerName,
      returnAll: returnAll,
    });
  },
  searchCareers: (options: CareerSearchOptions) => {
    return postRequest("/careers/search/", options);
  },

  // Liked careers
  getLikedCareers: () => {
    return cachedGetRequest("/liked-careers/");
  },
  likeCareer: (careerName: string) => {
    return postRequest("/liked-careers/", { career_name: careerName });
  },
  unlikeCareer: (careerName: string) => {
    return deleteRequest("/liked-careers/", { career_name: careerName });
  },

  // Career lengths
  getCareerLengths: () => {
    return cachedGetRequest("/career-lengths/");
  },

  // Career experiences
  getCareerExperiences: (careerName: string) => {
    return cachedGetRequest("/career-experiences/", { careerName: careerName });
  },
  getUserCareerExperiences: (userId: string) => {
    return cachedGetRequest("/user/career-experiences/", { userId: userId });
  },
  getCurrentUserCareerExperiences: () => {
    return cachedGetRequest("/current-user/career-experiences/");
  },
  postCurrentUserCareerExperience: (
    careerExperienceForm: CareerExperienceForm
  ) => {
    return postRequest(
      "/current-user/career-experiences/",
      careerExperienceForm
    );
  },
  putCurrentUserCareerExperience: (
    careerExperienceForm: CareerExperienceForm
  ) => {
    return putRequest(
      "/current-user/career-experiences/",
      careerExperienceForm
    );
  },

  // Career experience comments
  getCareerExperienceComment: (careerExperienceId: number) => {
    return cachedGetRequest("/career-experience-comments/", {
      careerExperienceId: careerExperienceId,
    });
  },
  postCareerExperienceComment: (
    careerExperienceCommentForm: CareerExperienceCommentForm
  ) => {
    return postRequest(
      "/career-experience-comments/",
      careerExperienceCommentForm
    );
  },

  // Career experience like
  likeCareerExperience: (careerExperienceId: number) => {
    return postRequest("/career-experience-likes/", {
      careerExperienceId: careerExperienceId,
    });
  },
  unlikeCareerExperience: (careerExperienceId: number) => {
    return deleteRequest("/career-experience-likes/", {
      careerExperienceId: careerExperienceId,
    });
  },
};
