import "./AppView.css";

import { Routes, Route, Navigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

import HomeView from "./HomeView/HomeView";
import AboutView from "./AboutView/AboutView";
import MotivationView from "./MotivationView/MotivationView";
import ContactView from "./ContactView/ContactView";
import ExploreView from "components/views/ExploreView/ExploreView";
import MyCareersView from "components/views/MyCareersView/MyCareersView";
import CuratedUsersView from "./CuratedUsers/CuratedUsers";
import UserAnswersView from "./UserAnswers/UserAnswers";

import routes from "services/routes";

function AppView() {
  const { isSignedIn } = useUser();

  return (
    <div className="app-view">
      <Routes>
        <Route path={routes.home.short} element={<HomeView />} />

        <Route path={routes.about.short} element={<AboutView />} />

        <Route path={routes.ourMotivation.short} element={<MotivationView />} />

        <Route path={routes.contact.short} element={<ContactView />} />

        <Route path={routes.explore.short + "/*"} element={<ExploreView />} />

        <Route path={routes.userAnswers.short + "/:userId"} element={<UserAnswersView />} />

        <Route path={routes.myCareers.short} element={<MyCareersView />} />

        <Route path={routes.curatedUsers.short} element={<CuratedUsersView />} />

        <Route path="/*" element={<Navigate to={routes.explore.short} />} />
      </Routes>
    </div>
  );
}

export default AppView;
