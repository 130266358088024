import "./ExploreSearchView.css";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Loader from "components/misc/Loader/Loader";
import UserSquare from "components/misc/UserSquare/UserSquare";

import { useNavigate, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { SignedIn, useUser } from "@clerk/clerk-react";

import { Career, CareerSearchOptions } from "services/datatypes";
import api from "services/api";
import routes from "services/routes";

function ExploreSearchView() {
  const navigate = useNavigate();
  let { isSignedIn, user, isLoaded } = useUser();

  const [searchOpen, setSearchOpen] = useState(false);
  const [loadingLikedCareers, setLoadingLikedCareers] = useState(false);
  const [likedCareers, setLikedCareers] = useState<Array<Career>>([]);
  const [careers, setCareers] = useState<Array<Career>>([]);
  const [careerResults, setCareerResults] = useState<Array<Career>>([]);
  const [careerResultsLoading, setCareerResultsLoading] = useState<
    boolean | null
  >(null);
  const [searchOptions, setSearchOptions] = useState<CareerSearchOptions>(
    {} as CareerSearchOptions
  );
  const [searchOptionMinimised, setSearchOptionMinimised] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    if (isSignedIn == true) {
      setLoadingLikedCareers(true);
      api.getLikedCareers().then((res) => {
        setLikedCareers(res);
        setLoadingLikedCareers(false);
      });
    }

    api.getCareers().then((res) => {
      setCareers(res);
    });
  }, [isSignedIn]);

  function canSubmit() {
    var res = false;
    for (const key in searchOptions) {
      if (
        (searchOptions as any)[key] != null &&
        (searchOptions as any)[key] != ""
      ) {
        res = true;
      }
    }
    return res;
  }

  function submit() {
    if (canSubmit() == false) {
      return;
    }

    setCareerResultsLoading(true);
    api.searchCareers(searchOptions).then((res) => {
      setCareerResults(res);
      setCareerResultsLoading(false);

      if (res.length == 1) {
        navigate(encodeURIComponent(res[0].name));
      }
    });
  }

  return (
    <div className="explore-search-view">
      <h1 className="section-title">Get Answers</h1>

      {isLoaded && (
        <>
          <div className="top-bar">
            <SignedIn>
              <div className="liked-careers anim-appear">
                <div className="title">
                  <FavoriteIcon />
                  <div className="title-text">Liked careers</div>
                </div>
                <div className="career-list">
                  {loadingLikedCareers == true && (
                    <div style={{ textAlign: "center" }}>
                      <Loader />
                    </div>
                  )}

                  {loadingLikedCareers == false && (
                    <>
                      {likedCareers.length == 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            opacity: "0.6",
                          }}
                        >
                          No liked careers yet
                        </div>
                      )}

                      {likedCareers.map((career) => (
                        <NavLink
                          to={
                            routes.explore.short +
                            "/" +
                            encodeURIComponent(career.name)
                          }
                          className="career anim-appear"
                        >
                          {career.name}
                        </NavLink>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </SignedIn>

            <div className="people-container anim-appear">
              <div className="people">
                {/* Me (for local dev) */}
                {/* <UserSquare userId="user_2Qz4Gjo10e2lwStVR9g8jzbBHPI" />
                <UserSquare userId="user_2Qz4Gjo10e2lwStVR9g8jzbBHPI" />
                <UserSquare userId="user_2Qz4Gjo10e2lwStVR9g8jzbBHPI" />
                <UserSquare userId="user_2Qz4Gjo10e2lwStVR9g8jzbBHPI" /> */}

                {/* Warren Buffett */}
                <UserSquare userId="user_2YIjKvWSv8WGLCJCJJn3GqVxppi" />
                {/* Roger Federer */}
                <UserSquare userId="user_2dP6vJZBnE7iYJGPYk7fbKqOE2H" />
                {/* Bill Gates */}
                <UserSquare userId="user_2YIiW77x9K4xLhhyYaNRk06eR1V" />
                {/* Indra Nooyi */}
                <UserSquare userId="user_2YItlLbN99EkVBTu4DOyPwnoCQR" />
              </div>

              <div
                className="button big"
                onClick={() => {
                  navigate(routes.curatedUsers.short);
                }}
              >
                See more
              </div>
            </div>
          </div>

          <div className="search-bar">
            <div className="bar">
              <Autocomplete
                freeSolo
                forcePopupIcon
                disableClearable
                open={searchOpen}
                onOpen={() => {
                  setSearchOpen(true);
                }}
                onClose={() => {
                  setSearchOpen(false);
                }}
                value={searchOptions.name_contains ?? ""}
                options={careers.map((career) => {
                  return career.name;
                })}
                onChange={(_, newValue) => {
                  setSearchOptions({
                    ...searchOptions,
                    name_contains: newValue || "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select or type career"
                    // label="Career name"
                    size="small"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ marginLeft: "2px", marginRight: "-5px" }}
                        >
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setSearchOptions({
                        ...searchOptions,
                        name_contains: e.target.value,
                      });
                    }}
                    onKeyDown={(e) => {
                      console.log(e);
                      if (e.code === "Enter") {
                        submit();
                        setSearchOpen(false);
                      }
                    }}
                  />
                )}
              />
            </div>
            <div className="submit" onClick={submit}>
              <div>Search</div>
            </div>
          </div>

          {/* OLD SEARCH FUNCTIONALITY (hopefully it will be brought back at some point) */}
          {/* <div
            className={
              "search-options box box-minimisable anim-appear" +
              (searchOptionMinimised == true
                ? " minimised"
                : searchOptionMinimised == false
                ? " maximised"
                : "")
            }
          >
            <div
              className="box-header"
              onClick={() => {
                setSearchOptionMinimised(!searchOptionMinimised);
              }}
            >
              <div className="title-icon">
                <TuneIcon />
              </div>
              <div className="title">
                Search options (fill in at least one field below)
              </div>
              <div className="flex-filler"></div>
              <div className="minimise-toggle">
                <KeyboardArrowUpIcon fontSize="large" />
              </div>
            </div>

            <div className="box-minimise">
              <div className="box-body">
                <Autocomplete
                  freeSolo
                  forcePopupIcon
                  disableClearable
                  value={searchOptions.name_contains ?? ""}
                  options={careers.map((career) => {
                    return career.name;
                  })}
                  onChange={(_, newValue) => {
                    setSearchOptions({
                      ...searchOptions,
                      name_contains: newValue || "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select or type"
                      label="Career name"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ marginLeft: "8px" }}
                          >
                            <BusinessCenterIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setSearchOptions({
                          ...searchOptions,
                          name_contains: e.target.value,
                        });
                      }}
                    />
                  )}
                />

                <TextField
                  fullWidth
                  className="mt-4"
                  label="What I love"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FavoriteIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSearchOptions({
                      ...searchOptions,
                      love_text: e.target.value,
                    });
                  }}
                />
                <TextField
                  fullWidth
                  className="mt-4"
                  label="What I don't love"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HeartBrokenIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSearchOptions({
                      ...searchOptions,
                      not_love_text: e.target.value,
                    });
                  }}
                />
                <TextField
                  fullWidth
                  className="mt-4"
                  label="What skills and qualities I have"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmojiEventsIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setSearchOptions({
                      ...searchOptions,
                      skills_text: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="box-footer">
                <div
                  className={
                    "button" + (canSubmit() == false ? " disabled" : "")
                  }
                  onClick={submit}
                >
                  Search
                </div>
              </div>
            </div>
          </div> */}

          {careerResultsLoading == true && (
            <>
              <div style={{ textAlign: "center", marginTop: "60px" }}>
                <Loader />
              </div>
            </>
          )}

          {careerResultsLoading == false && (
            <>
              {careerResults.length == 0 && (
                <div style={{ textAlign: "center" }}>
                  No matching careers yet
                </div>
              )}

              {careerResults.map((career) => (
                <NavLink key={career.name} to={encodeURIComponent(career.name)}>
                  <div className="career-result">{career.name}</div>
                </NavLink>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ExploreSearchView;
